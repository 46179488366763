// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent11() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_26_04_25.jpg",
        title: 'Toronto Concert Orchestra',
        subtitle: 'Marcus Scholtz conducting',
        date: 'Saturday 26 April 2025 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/barrie-concerts-sat-series-2024-2025/'
    });

    const [eventData] = useState({
        program: `Antonin Dvorak	 2 Humoresques op. 101
                                  		no. 7 Poco lento e grazioso
                                  		no. 8 Poco andante

Josef Suk       	       	 Things Lived and Dreamt op. 30
                                 		 lX - Poco Andante, ‘Whispering and mysterious’
                                 		 V- On the recovery of my son Adagio ‘Calm with deep emotion’
                                 		 Vlll ‘Delicate, Warbling’

Josef Suk		 Spring op. 22a
                                 		No. 5 - Longing  

      				
Vítěslava Kaprálová   April preludes op. 13
                                   		l Allegro non troppo	                           
                                 		 lll Andante semplice
                                 		 lV Vivo

 Claude Debussy         3 Preludes 
                          		Bruyères 
                         		 Les Collines d’Anacapri
                          		Feux d’artifice
                        

                      
INTERMISSION


Valentin Silvestrov      3 Postludes op. 64

Frederick Chopin          Mazurka op. 59 no. 2
                     		 Ballade no. 4  
                     		 Scherzo no. 1  
`,

performers: `The Toronto Concert Orchestra (TCO) is an itinerant professional orchestra renowned for its diverse musical offerings, performing from spring through fall in some of downtown Toronto’s magnificent concert venues and churches, as well as in communities across Ontario. Founded by internationally recognized conductor and music director Kerry Stratton, TCO served as the orchestra-in-residence at the historic Casa Loma, where it established the beloved summer series, Symphony in the Gardens.

Since presenting its first symphony concert at Toronto’s Phoenix Concert Theatre in 2008, TCO has grown in popularity, particularly with its summer series, now known as Symphony in the City. TCO is dedicated to socially responsible and diverse programming, commissioning new works from Canadian and international composers and reviving masterpieces by overlooked composers of the past. A notable commission is Ian Cusson’s Five Songs on Poems by Marilyn Dumont, highlighting the mistreatment of indigenous peoples by settlers in Canada.

TCO attracts distinguished guest artists and conductors, performing at prominent venues in Toronto to appeal to a wide audience. Their genre-inclusive summer series features music ranging from Mozart’s mixed-race contemporary, Joseph Bologne, to the legendary Elton John.`,
        performerLink: 'https://www.tcomusic.ca/',
        ticketInfo: `Purchase your season subscription early. 6 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $110 Adults In-person: -  $190. Students $35 either in-person or live-streamed`,

    });

    const barrieConcert = [
        {
            title: 'Duo Turgeon',
            description: 'From Bruce with Love',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
            link: '/SingleEvent6'
        },
        {
            title: 'Dévah Quintet',
            description: 'Dévah Quintet',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            link: '/SingleEvent7'
        },
        {
            title: 'True North Brass',
            description: 'Dancing Across the Global',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            link: '/SingleEvent8'
        },
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent11;
