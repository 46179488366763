import React from "react";
import '../../App.css';
import HeroSectionEventCard from '../HeroSectionEventCard';
import { Box, Typography } from '@mui/material';
import MUICard4 from '../MUICard4';


function SupportUs() {
    const eventData = {
      backgroundImage: '/images/bg-1.jpg',
        heading: 'Support Us',
        description: 'Help us bring exceptional music to Barrie. Your support through donations and sponsorships keeps the arts alive!'
      };



      const card4Data1 = [
        {
          src: "images/Logo/TD.jpg",
        }
      ];

      const card4Data2 = [
        {
          src: "images/Logo/Owen&AssociatesLaw.jpg",
        },
        {
          src: "images/Logo/CanadianTie.png",
        }, 
        {
          src: "images/Logo/Aerarium Group.png",
        },
        {
          src: "images/Logo/McBrideRobillard.png",
        }
        
      ];

      const card4Data3 = [
        {
          src: "images/Logo/lifelogo.png",
        }
      ];


      const card4Data4 = [
        {
          src: "images/Logo/Brian.jpg",
        }
      ];



      const card4Data5 = [
        {
          src: "images/Logo/Creations by Christina.jpg",
        },
        {
          src: "images/Logo/Owlcity.jpeg",
        },
        {
          src: "images/Logo/IG.png",
        },

        {src: "images/Logo/Musicpro.jpeg",},

        {src: "images/Logo/FoxBakery.jpg",}
  
      ];


      return(
        <>
        <HeroSectionEventCard
        backgroundImage={eventData.backgroundImage}
        heading={eventData.heading}
        description={eventData.description}
      />
      <Box sx={{ padding: '40px', backgroundColor: '#f5f5f5', color: '#000', textAlign: 'left' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Donations
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Barrie Concert Association strives to bring you outstanding programs of music and performance at a very reasonable price. However, ticket sales cover only part of the cost of presenting these concerts. We welcome any donations which will allow us to keep presenting these concerts.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You may donate by:
      </Typography>
      <Typography variant="body1" component="ol" gutterBottom>
        <li>
          Sending a cheque to Barrie Concert Association, PO Box 452, Barrie, ON, L4M 4T7, along with your name, address or email address, and the word donation on the memo item of the cheque.
        </li>
        <li>
          Sending an e-transfer to <a href="mailto:payments@barrieconcerts.org">payments@barrieconcerts.org</a>, with your name and email address (for a receipt) and the word "donation" in the comment line.
        </li>
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Barrie Concert Association is a registered charity and receipts will be issued for any donations over $20.
      </Typography>
    </Box>
    <Box> 
       <Typography variant="h4" gutterBottom sx={{ color: '#000000', textAlign: 'center', mt: '5px'}}>
            Gold Sponsors
        </Typography>
        <MUICard4 cards4Data={card4Data1} />
        <Typography variant="h4" gutterBottom sx={{ color: '#000000', textAlign: 'center', mt: '5px'}}>
            Silver Sponsors
        </Typography>
        <MUICard4 cards4Data={card4Data2} />
        <Typography variant="h4" gutterBottom sx={{ color: '#000000', textAlign: 'center', mt: '5px'}}>
            Sponsors
        </Typography>
        <MUICard4 cards4Data={card4Data3} />
        <Typography variant="h4" gutterBottom sx={{ color: '#000000', textAlign: 'center', mt: '5px'}}>
            Gold Donor
        </Typography>
        <MUICard4 cards4Data={card4Data4} />
        <Typography variant="h4" gutterBottom sx={{ color: '#000000', textAlign: 'center', mt: '5px'}}>
           Suppliers
        </Typography>
        <MUICard4 cards4Data={card4Data5} />
    </Box>  
        </>
        
      )

}

export default SupportUs; 